<template>
  <b-row align-h="center" style="margin-top:10vh">
    <b-col cols="5" class="p-4 border" style="border-radius: 5px;height: 450px;">
      <div class="text-center">
        <img class="mb-5 mt-3" width="300" src="/images/R-Cycle_Logo_RGB_300.png">

        <div v-if="!clientCheck.success">
          <b-spinner v-if="!clientCheck.done" style="margin-top: 25%"/>
          <div v-else>
            <h1 class="mb-4" style="font-size: 1.8rem">Authorization failed</h1>
            <p>
              We could not verify the client you are trying to authorize to. Please try again or
              <a href="mailto:admin@r-cycle.de">contact support</a>.
            </p>
          </div>
        </div>

        <div v-else-if="!isAuthenticated">
          <p class="mb-5">
            Please <strong>login first</strong> to continue the authorization.
          </p>
          <LoginForm/>
        </div>

        <div v-else>
          <h1 class="mb-4" style="font-size: 1.8rem">Authorization</h1>
          <p>
            Hey <span class="font-weight-bold">{{ getUsername }}</span>, you are about to grant access to
            client <span class="font-weight-bold">{{ expected_oauth_parameters.client_id }}</span>.
          </p>
          <b-button id="btn-authorize" variant="danger" class="mt-3" @click="grant">
            <b-spinner class="mr-1" v-show="loading" small></b-spinner>
            Authorize
          </b-button>
          <b-tooltip target="btn-authorize" triggers="hover">
            You will be redirected to <b>{{ expected_oauth_parameters.redirect_uri }}</b>
          </b-tooltip>

          <b-button id="btn-switch" variant="warning" class="mt-3 ml-3" @click="logout">
            <b-spinner class="mr-1" v-show="loading" small></b-spinner>
            Switch account
          </b-button>
          <b-tooltip target="btn-switch" triggers="hover">
            You will be logged out
          </b-tooltip>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {mapGetters} from 'vuex';
import LoginForm from '../components/LoginForm';
import {AUTH_LOGOUT} from '@/vuex/actions/auth';

export default {
  name: 'oauthAuthorize',
  data: function () {
    return {
      loading: false,
      clientCheck: {
        done: false,
        success: false
      },
      expected_oauth_parameters: { // see https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.1
        client_id: '',
        response_type: '', // we only handel `response_type=code`!
        redirect_uri: '',
        state: '',
        scope: ''
      },
      authcode: '',
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      getUsername: 'getUsername'
    })
  },
  methods: {
    async checkClient() {
      var result = await this.$axios
        .get('/oauth/client/' + this.expected_oauth_parameters.client_id)
        .catch(e => {
          console.error('Error checking client: ' + e);
        });

      this.clientCheck.success =
        (result.data.id === this.expected_oauth_parameters.client_id) &&
        (this.expected_oauth_parameters.redirect_uri.startsWith('https://' + result.data.hostname)) &&
        (this.expected_oauth_parameters.response_type === 'code');

      this.clientCheck.done = true;
    },
    grant() {
      this.loading = true;
      this.$axios
        .get('/oauth/authcode?scope=' + encodeURIComponent(this.expected_oauth_parameters.scope) + "&client_id=" + this.expected_oauth_parameters.client_id)
        .then(response => {
          if (response.data.token) {
            this.authcode = response.data.token;

            // see https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2
            window.location.href = this.expected_oauth_parameters.redirect_uri
              + (this.expected_oauth_parameters.redirect_uri.indexOf('?') === -1 ? '?' : '&')
              + 'state='
              + this.expected_oauth_parameters.state
              + '&code='
              + this.authcode
          } else {
            console.error('GET /oauth/authcode response is missing at least one expected parameter');
            console.table(response);
          }
        })
        .catch(e => {
          // todo: error handling per https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2.1
          console.error('Error Getting Auth Code From Backend: ' + e);
          this.flashMessage.error({
            title: 'Authorization failed',
            message: 'Error getting auth code. Please contact support.'
          });
        });
      this.loading = false;
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
    }
  },
  mounted() {
    // see https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.1 for the expected parameters
    this.expected_oauth_parameters = {...this.expected_oauth_parameters, ...this.$route.query};
    this.checkClient();
  },
  components: {
    LoginForm
  }
};
</script>

<style scoped>
article img {
  margin-left: auto;
  margin-right: auto;
  width: 60% !important;
}
</style>
